import { ARCanvas, ARMarker } from "@artcom/react-three-arjs";
import React, { Suspense, useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { Html, Splat, useProgress } from "@react-three/drei";
const Loader = () => {
  const { progress } = useProgress();
  return <Html>{progress} % loaded</Html>;
};
const splatUrls = [
  "https://d2mjt9y3unfs9x.cloudfront.net/hekisui.splat",
  "https://d2mjt9y3unfs9x.cloudfront.net/himeringo.splat",
  "https://d2mjt9y3unfs9x.cloudfront.net/kaede.splat",
  "https://d2mjt9y3unfs9x.cloudfront.net/kinkan.splat",
  "https://d2mjt9y3unfs9x.cloudfront.net/kotaro.splat",
  "https://d2mjt9y3unfs9x.cloudfront.net/kouchouboku.splat",
  "https://d2mjt9y3unfs9x.cloudfront.net/kuchinashi.splat",
  "https://d2mjt9y3unfs9x.cloudfront.net/kuromatsu.splat",
  "https://d2mjt9y3unfs9x.cloudfront.net/minibara.splat",
  "https://d2mjt9y3unfs9x.cloudfront.net/mitarai01.splat",
  "https://d2mjt9y3unfs9x.cloudfront.net/mitarai02.splat",
  "https://d2mjt9y3unfs9x.cloudfront.net/miyasamakaede.splat",
  "https://d2mjt9y3unfs9x.cloudfront.net/murasakisikibu.splat",
  "https://d2mjt9y3unfs9x.cloudfront.net/pirakansa.splat",
  "https://d2mjt9y3unfs9x.cloudfront.net/sarusuberi.splat",
  "https://d2mjt9y3unfs9x.cloudfront.net/shimpaku_isizuki.splat",
];
const RandomSplat = () => {
  const [splatUrl, setSplatUrl] = useState("");
  useEffect(() => {
    const randomUrl = splatUrls[Math.floor(Math.random() * splatUrls.length)];
    setSplatUrl(randomUrl);
  }, []);
  return splatUrl ? <Splat src={splatUrl} scale={[5, 5, 5]} /> : null;
};
const ARApp = () => (
  <ARCanvas
    camera={{ fov: 90, position: [0, 0, 0], near: 0.1 }}
    gl={{
      antialias: true,
      powerPreference: "high-performance",
    }}
    onCameraStreamReady={() => console.log("Camera stream ready")}
    onCameraStreamError={() => console.error("Camera stream error")}
    onCreated={({ gl }) => {
      gl.setSize(window.innerWidth, window.innerHeight);
    }}
    patternRatio={0.9}
    sourceType={"webcam"}
  >
    <ARMarker
      params={{ smooth: true }}
      type={"pattern"}
      patternUrl={"data/gray_090.patt"}
      detectionMode={"Mono"}
      minConfidence={0.001}
      onMarkerFound={() => {
        console.log("Marker Found");
      }}
    >
      <Suspense fallback={<Loader />}>
        <RandomSplat />
      </Suspense>
    </ARMarker>
  </ARCanvas>
);
createRoot(document.getElementById("root")).render(<ARApp />);
